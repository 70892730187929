import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Head from 'next/head';
import Image from 'next/image';
import { useRouter } from 'next/router';
// import { GoogleAnalytics } from 'nextjs-google-analytics';
import { event } from 'nextjs-google-analytics';
import React, { useEffect, useRef, useState } from 'react';
import { useTypewriter } from 'react-simple-typewriter';
// import { TextInput } from 'react-native';
import styled from 'styled-components';

// eslint-disable-next-line
import MFMLogo from '../public/mfm.png';
import { Box } from '../src/components/common/Box';
import { Flex } from '../src/components/common/Flex';
import { useBreakpoints } from '../src/hooks/useBreakpoints';

const StyledForm = styled.form`
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  display: flex;
`;

const StyledFlex = styled(Flex)`
  background-color: #ffffff;
  // min-width: 600px;

  min-height: 50px;
  padding: 12px 20px;
  border-radius: 8px;
  // border: 1px solid #ffb800;
  box-shadow: 0px 4px 20px rgba(41, 40, 45, 0.09);

  &:focus {
    outline: none;
  }
`;

// const StyledTextInput = styled(TextInput)`
//   font-size: 16px;
//   border: none;
//   &:focus {
//     outline: none;
//   }
// `;

const StyledButton = styled.button`
  border: none;
  &:hover {
    box-shadow: inset 0 0 0 1px #ffffff;
  }
`;

const StyledInput = styled.input`
  font-size: 16px;
  color: black;
  &:focus {
    outline: none;
  }
  &::placeholder {
    font-style: italic;
  }
`;

export default function Home() {
  const [searchQuery, setSearchQuery] = useState('');
  const router = useRouter();
  const { isDesktop } = useBreakpoints();
  const [isKeyboardVisible, setKeyboardVisible] = useState(false);
  const inputRef = useRef<any>();
  const [isFocused, setIsFocused] = useState(false);

  // function handleClick() {
  //   inputRef.current.focus();
  // }

  function search(e: any) {
    e.preventDefault();
    const searchTerm = searchQuery?.trim().toString();
    if (!searchTerm) return;
    setIsFocused(false);
    router.push(`search?term=${searchTerm}`);
    event('submit_form', {
      category: 'Search Term',
      label: searchTerm,
    });
  }

  const [text, _helper] = useTypewriter({
    /* Config */
    words: ['"Trillionaire energy"', '"No small boy stuff"', '"Manifest cowboy"'],
    loop: 0,
  });

  useEffect(() => {
    !isDesktop &&
      window?.visualViewport?.addEventListener('resize', () => {
        setKeyboardVisible(!isKeyboardVisible);
        if (!isKeyboardVisible) {
          search;
        }
      });
    if (isFocused) {
      // eslint-disable-next-line
      inputRef.current && (inputRef?.current as any)?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isKeyboardVisible, isFocused]);
  return (
    // @ts-ignore
    <Flex
      alignItems={'center'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      style={{ minHeight: '100vh' }}
    >
      <Head>
        <title>SearchMFM</title>
        <meta content='Search The My First Million Podcast' name='description' />
        <link href='/favicon.ico' rel='icon' />
        <link
          href='https://res.cloudinary.com/dyoa1xeux/image/upload/v1667096288/SearchMFM_iaxixt.png'
          rel='shortcut icon'
        />
      </Head>
      {/* <GoogleAnalytics gaMeasurementId='G-53TZMZYL03' trackPageViews={{ ignoreHashChange: true }} /> */}

      <StyledForm onSubmit={search}>
        <Flex alignItems={'center'} flexDirection={'column'} paddingBottom={isDesktop ? '20px' : '20px'}>
          <Box borderRadius={10} marginBottom={10}>
            <Image alt={'Search The My First Million Podcast'} height={250} src={MFMLogo} width={250} />
          </Box>
          {isDesktop ? (
            <>
              <h1
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '40px',
                  marginBlockStart: '0',
                  marginBlockEnd: '0',
                  letterSpacing: '1.5px',
                  marginTop: '0px',
                  marginBottom: '16px',
                }}
              >
                Search{' '}
                <span
                  style={{
                    backgroundColor: '#DFB956',
                    color: 'white',
                    padding: '6px 12px 6px 12px',
                    borderRadius: '16px',
                  }}
                >
                  My First Million
                </span>{' '}
              </h1>

              <h2
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '250',
                  fontSize: '18px',
                  lineHeight: '24px',
                  letterSpacing: '1px',
                  marginTop: '20px',
                  textAlign: 'center',
                }}
              >
                Find the exact moment an idea was mentioned.
              </h2>
            </>
          ) : (
            <>
              <h1
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '25px',
                  letterSpacing: '1.5px',
                  marginTop: '0px',
                  marginBottom: '16px',
                  textAlign: 'center',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  lineHeight: '40px',
                }}
              >
                Search{' '}
                <span
                  style={{
                    fontFamily:
                      '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                    backgroundColor: '#ffffff',
                    color: 'black',
                    padding: '6px 12px 6px 12px',
                    borderRadius: '16px',
                  }}
                >
                  My First Million
                </span>{' '}
              </h1>
              <h2
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  fontStyle: 'normal',
                  fontWeight: '250',
                  fontSize: '14px',
                  lineHeight: '24px',
                  letterSpacing: '1px',
                  marginTop: '10px',
                  textAlign: 'center',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                }}
              >
                Find the exact moment an idea was mentioned.
              </h2>
            </>
          )}
        </Flex>

        <StyledFlex
          alignItems='center'
          justifyContent='center'
          marginBottom={10}
          onClick={() => setIsFocused(true)}
          width={isDesktop ? '33%' : '80%'}
        >
          {isDesktop && (
            <Flex>
              <MagnifyingGlassIcon color='gray' height={'1.25rem'} style={{ marginRight: '1.25rem' }} />
            </Flex>
          )}

          {!isFocused && (
            <>
              {/* <StyledTyper
              options={{
                strings: ['Sleeping better', 'How to improve my relationships?', 'Getting over anxiety'],
                autoStart: true,
                loop: true,
              }}
            /> */}
              <span
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  width: '100%',
                  fontStyle: 'italic',
                  color: '#ACABAB',
                }}
              >
                {text}
              </span>
              <StyledInput
                enterKeyHint={'go'}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onSubmit={search}
                placeholder={''}
                ref={inputRef}
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  width: '100%',
                  backgroundColor: 'inherit',
                  border: 'none',
                  display: !isFocused && 'none',
                }}
                type={'text'}
                value={searchQuery}
              />
            </>
          )}
          {isFocused && (
            <>
              <StyledInput
                enterKeyHint={'go'}
                onBlur={() => setIsFocused(false)}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={() => setIsFocused(true)}
                onSubmit={search}
                placeholder={''}
                ref={inputRef}
                style={{
                  fontFamily:
                    '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif',
                  width: '100%',
                  backgroundColor: 'inherit',
                  border: 'none',
                }}
                type={'text'}
                value={searchQuery}
              />
            </>
          )}
          {!isDesktop && (
            <button
              onClick={search}
              style={{
                width: '30px',
                height: '100%',
                background: 'none',
                border: 'none',
                padding: '0px',
                color: 'white',
                cursor: 'pointer',
                outline: 'inherit',
              }}
            >
              <MagnifyingGlassIcon color='gray' height={'1.5rem'} />
            </button>
          )}
        </StyledFlex>
        {/* <button
        type='submit'
        // onClick={search}
        style={{
          width: '30px',
          height: '100%',
          background: 'none',
          border: 'none',
          padding: '0px',
          color: 'white',
          cursor: 'pointer',
          outline: 'inherit',
        }}
      >
         <MagnifyingGlassIcon /> 
      </button> */}
        <StyledButton
          onSubmit={search}
          style={{
            backgroundColor: 'rgba(48, 49, 52,52)',
            display: 'none',
            padding: '0.75rem',
            borderRadius: '0.375rem',
            color: 'white',
            cursor: 'pointer',
            outline: 'inherit',
          }}
          type='submit'
        >
          Search My First Million
        </StyledButton>
        <input style={{ display: 'none' }} type='submit' />
      </StyledForm>
    </Flex>
  );
}
